import { onMount } from "solid-js";
import { useWire } from "~/wire";
import { StButton } from "~/components/buttons";
import { TbSparkles } from "solid-icons/tb";

export default () => {
  const wire = useWire();
  onMount(() => {
    wire.services.threads.send(
      wire.services.threads.eventFactory.newResetEvent(),
    );
  });

  return (
    <div>
      <div class="fixed inset-0 bg-white dark:bg-indigo-1100 flex flex-col items-center justify-center text-center gap-4 animate-fade-in">
        <h2 class="text-black dark:text-white text-3xl font-semibold">
          Page Not Found
        </h2>
        <p class="text-slate-800 dark:text-indigo-200 mt-4">
          The page you are looking for is not available.
        </p>
        <p class="text-slate-800 dark:text-indigo-200 mt-4 mb-8">
          We have something else for you!
          <br />
          Try our new <a href="/">beta platform</a>.
        </p>
        <StButton
          size="lg"
          onClick={() => {
            window.location.href = "/";
          }}
          icon={TbSparkles}
        >
          Use AI Tools
        </StButton>
      </div>
    </div>
  );
};
